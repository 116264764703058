import React from 'react'
import { useState } from 'react'
import { dataProgram } from './data/program'
import Programcard from './Programcard'

const Program = () => {

    const [onHover, setOnHover] = useState("");

  return ( 
    <div className="w-full bg-primary flex flex-col items-center justify-center pt-10 my-10 ">
        <span className="flex-shrink mx-4 bg-[#f5f5f5] my-10 text-hitam px-8 py-2 rounded-full font-bold md:text-xl">
                Program dan Kegiatan Pesantren
              </span>
        <div className="w-full xl:w-[1300px]  mb-[100px]  grid grid-cols-1  md:grid-cols-2 xl:grid-cols-3 gap-y-[52px] gap-x-[24px] md:px-0 px-4">
          {dataProgram?.map((service, index) => (
            <Programcard
              key={index}
              service={service}
              onHover={onHover}
              setOnHover={setOnHover}
            />
          ))}
        </div>
      </div>
  )
}

export default Program
import React from 'react'
import { useState } from 'react'
import gambarLapangan from '../../assets/lapangan.jpg'
import gambarMasjid from '../../assets/login.png'
import gambarMadrasah from '../../assets/madrasah.jpg'
import gambarQismart from '../../assets/qismart.jpg'
import gambarAula from '../../assets/aula.jpg'
import gambar from '../../assets/login.png'
import gambarAsramaPutra from '../../assets/asramaputra.jpg'
import kamarmandi from '../../assets/kamar mandi.jpg'
import akael from '../../assets/akael.jpg'
import asrama from '../../assets/asrama.jpg'
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Fasilitas = () => {


    const settings = {
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    }

    const locations = [
        { name: 'Madrasah', image: gambarMadrasah, size: '' },
        { name: 'Qismart', image: gambarQismart, size: '' },
        { name: 'Masjid', image: gambarMasjid, size: '' },
        { name: 'Lapangan', image: gambarLapangan, size: '' },
        { name: 'Aula', image: gambarAula, size: '' },
        { name: 'Asrama Putra', image: gambarAsramaPutra, size: '' },
        { name: 'Kamar Mandi', image: kamarmandi, size: '' },
        { name: 'Akael', image: akael, size: '' },
        { name: 'Asrama', image: asrama, size: '' },

        // kurang asrama putri, km putri
    ];

    return (
        <div className="w-full flex flex-col items-center justify-center pt-4 px-8" >
            <div className='py-2 px-4 rounded-xl bg-primary mb-4'>
                <p className='text-md text-white'>
                    Fasilitas Pesantren
                </p>
            </div>

            {/* Desktop */}
            <div className='md:block hidden w-full' >
                <div className='w-full flex justify-center mt-2'>
                    <div className='flex p-3 w-[30%] h-[280px] object-cover relative'>
                        <img src={asrama} className='w-full h-full object-cover' alt="" />
                        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-primary to-transparent m-3">
                            <p className="text-white text-center pb-2">Asrama</p>
                        </div>
                    </div>
                    <div className='relative flex p-3 w-[30%] h-[280px] object-cover'>
                        <img src={gambarMasjid} className='w-full h-full object-cover' alt="" />
                        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-primary to-transparent m-3">
                            <p className="text-white text-center pb-2">Masjid</p>
                        </div>
                    </div>
                    <div className='relative flex p-3 w-[40%] h-[280px] object-cover'>
                        <img src={gambarQismart} className='w-full h-full object-cover' alt="" />
                        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-primary to-transparent m-3">
                            <p className="text-white text-center pb-2">Qismart</p>
                        </div>
                    </div>
                </div>
                <div className='w-full flex justify-center'>
                    <div className='relative flex p-3 w-[45%] h-[280px] object-cover'>
                        <img src={gambarMadrasah} className='w-full h-full object-cover' alt="" />
                        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-primary to-transparent m-3">
                            <p className="text-white text-center pb-2">Madrasah</p>
                        </div>
                    </div>
                    <div className='relative flex p-3 w-[30%] h-[280px] object-cover'>
                        <img src={gambarAula} className='w-full h-full object-cover' alt="" />
                        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-primary to-transparent m-3">
                            <p className="text-white text-center pb-2">Aula</p>
                        </div>
                    </div>
                    <div className='relative flex p-3 w-[25%] h-[280px] object-cover'>
                        <img src={gambarAsramaPutra} className='w-full h-full object-cover' alt="" />
                        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-primary to-transparent m-3">
                            <p className="text-white text-center pb-2">Asrama Putra</p>
                        </div>
                    </div>
                </div>
                <div className='w-full flex justify-center'>
                <div className='relative flex p-3 w-[25%] h-[280px] object-cover'>
                        <img src={akael} className='w-full h-full object-cover' alt="" />
                        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-primary to-transparent m-3">
                            <p className="text-white text-center pb-2">Akael</p>
                        </div>
                    </div>
                    <div className='relative flex p-3 w-[40%] h-[280px] object-cover'>
                        <img src={kamarmandi} className='w-full h-full object-cover' alt="" />
                        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-primary to-transparent m-3">
                            <p className="text-white text-center pb-2">Kamar Mandi</p>
                        </div>
                    </div>
                    
                    <div className='relative flex p-3 w-[35%] h-[280px] object-cover'>
                        <img src={gambarLapangan} className='w-full h-full object-cover' alt="" />
                        <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-primary to-transparent m-3">
                            <p className="text-white text-center pb-2">Lapangan</p>
                        </div>
                    </div>
                </div>
            </div >

            {/* Mobile */}
            < div className=' md:hidden block w-full' >
                <Slider {...settings}>
                    {locations.map((location, index) => (
                        <div key={index} className='relative flex p-3 w-full h-[200px] object-cover'>
                            <img src={location.image} className='w-full h-full object-cover' alt={location.name} />
                            <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-primary to-transparent m-3">
                                <p className="text-white text-center pb-2">{location.name}</p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div >
        </div >
    )
}

export default Fasilitas
import React from "react";
import Sidebar from "../../components/sidebar";
import Produk from '../../components/Admin/produk'

const ProdukAdmin = () => {
  return (
    <div className="md:ml-64 bg-[#ECECEC] md:flex">
      <Sidebar />
      <Produk />
    </div>
  );
};

export default ProdukAdmin;

import React from "react";
import { Route } from "react-router-dom";
import Hero from "./components/Profile/Hero";
import Home from "./pages/Profile/Home";
import Swalayan from "./pages/Swalayan/Swalayan";
import Atmint from "./pages/Admin/home";
import DetailBerita from "./components/Detail/detailBerita";
import AdmintBerita from "./pages/Admin/berita";
import AtmintProduk from "./pages/Admin/produk";
import TambahProduk from "./pages/Admin/tambahProduk";
import TambahBerita from "./pages/Admin/tambahBerita";
import EditProduk from "./pages/Admin/editProduk";
import LoginCMS from "./components/Admin/login";
import ProtectedRoute from "./protectedRoutes";
import EditBerita from "./pages/Admin/editBerita";

const routes = [
  <Route path="/" element={<Home />} />,
  <Route path="/hero" element={<Hero />} />,
  <Route path="/loginmaqis" element={<LoginCMS />} />,
  <Route path="/belanja" element={<Swalayan />} />,
  <Route path="/detail/:id" element={<DetailBerita/>} />,
  <Route element={<ProtectedRoute />}>
    <Route path="/AdminMaqis/produk" element={<AtmintProduk />} />
    <Route path="/AdminMaqis" element={<Atmint />} />
    <Route path="/AdminMaqis/berita" element={<AdmintBerita />} />
    <Route path="/AdminMaqis/produk/tambah" element={<TambahProduk />} />
    <Route path="/AdminMaqis/produk/update/:id" element={<EditProduk />} />,
    <Route path="/AdminMaqis/berita/tambah" element={<TambahBerita />} />
    <Route path="/AdminMaqis/berita/update/:id" element={<EditBerita />} />
  </Route>,
];
export default routes;

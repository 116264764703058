import React, { useState } from "react";
import { useNavigate } from 'react-router-dom'
import backgroundLogin from "../../assets/login.png";
import alhamidy from "../../assets/logo.svg"
import axios from "axios";
import { API_URL } from "../../constant.js";

const LoginCMS = () => {
  const navigate = useNavigate();
  const [input, setInput] = useState({
    username: "",
    password: "",
  });


  const handleChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handleSubmit = async(e) => {
    e.preventDefault();

    try {
      let { username, password } = input;
      await axios
      .post(`${API_URL}/cms/login`, { username, password })
      .then((res) => {
          const token = res.data.token
          localStorage.setItem('token', token)
          navigate('/AdminMaqis')
          
        })
      
    } catch (err) {
      alert("Invalid Password or Username");
    }
  };
  return (
    <section className="relative flex h-screen bg-white md:justify-normal justify-center">
      <div className="hidden md:flex md:flex-[1_1_60%]">
        <img
          src={backgroundLogin}
          alt="login"
          className="h-screen shadow-[8px_8px_10px_rgba(0,0,0,0.25)] rounded-tr-[50px]"
        />
      </div>

      <div className=" flex-[1_1_40%] md:mt-16 justify-center">
        <div className="w-full px-8 md:px-24 flex flex-col mt-16">
          <div className="flex flex-col items-center mb-10 md:mb-4">
        <div className="md:text-3xl text-lg font-bold text-primary text-center mb-10">SELAMAT DATANG DI PORTAL ADMIN</div>

            <div className="w-[140px] h-[140px] md:w-[220px] md:h-[220px]">
              <img src={alhamidy} alt="growlab" className="w-full" />
            </div>
          </div>
          <form action="" onSubmit={handleSubmit}>
            <div className="my-4">
              <input
                type="text"
                placeholder="Nama pengguna"
                name="username"
                value={input.username}
                onChange={handleChange}
                className="px-4 py-2 border-2 border-primary rounded-xl w-full focus:outline-none"
              />
            </div>
            <div className="my-6">
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={input.password}
                onChange={handleChange}
                className="px-4 py-2 border-2 border-primary rounded-xl w-full focus:outline-none"
              />
            </div>

            <div className="flex justify-center">
              <button
                className="bg-primary text-white px-6 py-2 border border-primary rounded-xl"
                type="submit"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default LoginCMS;

import React from "react";
import banner from '../../assets/banner-1.png'
import Logo from "../../assets/logo.svg";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineMail } from "react-icons/md";
import { FaInstagram } from "react-icons/fa6";
import { FaWhatsapp } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
import { useState, useEffect } from "react";

const Footer = () => {
    const [year, setYear] = useState(new Date().getFullYear());

    useEffect(() => {
        setYear(new Date().getFullYear());
    }, []);

    return (
        <div className="">
            <div className="md:flex items-center text-sm bg-primary p-6">
                <div className="flex justify-center items-center w-[20%]">
                    <img className="" src={Logo} alt="Logo" width={150}></img>
                </div>
                <div className="mt-2 md:hidden block">
                    <h1 className="font-medium text-white">Pesantren maQis Al Hamidy 4</h1>
                    <p className="text-white font-light text-xs">Jalan Raya Pasean No.KM 02, Area Sawah/Kebun, Batukerbuy, Kec. Pasean,Kabupaten Pamekasan, Jawa Timur 69356</p>
                </div>
                <div className="md:w-[50%]">
                    <a href="https://maps.app.goo.gl/wtNtbY8yKxW6MdoS8" target="_blank" className="flex items-center md:mb-4 ">
                        <div className="p-2 md:block hidden">
                            <IoLocationOutline className="bg-white text-2xl p-1 rounded-full" />
                        </div>
                        <div className="text-white p-0 m-0 leading-tight text-md md:block hidden">
                            <p>Jalan Raya Pasean No.KM 02, Area Sawah/Kebun, <br /> Batukerbuy, Kec. Pasean, Kabupaten Pamekasan, <br />Jawa Timur 69356</p>
                        </div>
                    </a>
                    <a href="mailto:alhamidymaqis@gmail.com" target="_blank" className="flex items-center ">
                        <div className="p-2 md:block hidden">
                            <MdOutlineMail className="bg-white text-2xl p-1 rounded-full" />
                        </div>
                        <div className="text-md md:block hidden">
                            <p className="text-white">alhamidymaqis@gmail.com</p>
                        </div>
                    </a>
                </div>


                <a href="mailto:alhamidymaqis@gmail.com" target="_blank" className="md:w-[30%] md:block flex justify-between mt-4">
                    <div className="flex items-center md:hidden ">
                        <div className="p-2">
                            <MdOutlineMail className="bg-white text-3xl p-1 rounded-full" />
                        </div>
                    </div>
                    <a href="https://www.instagram.com/maqis_alhamidy?igsh=MW9nZWw4eXdncmszcA==" target="_blank" className="flex items-center">
                        <div className="p-2">
                            <FaInstagram className="bg-white text-3xl p-1 rounded-full" />
                        </div>
                        <div className="text-white text-md md:block hidden">
                            <p>@maqis_alhamidy</p>
                        </div>
                    </a>
                    <a href="https://wa.me/6287860317393" target="blank" className="flex items-center">
                        <div className="p-2">
                            <FaWhatsapp className="bg-white text-3xl p-1 rounded-full" />
                        </div>
                        <div>
                            <p className="text-white text-md md:block hidden">0878-6031-7393</p>
                        </div>
                    </a>
                    <a href="https://www.facebook.com/maqisalhamidy4?mibextid=ZbWKwL" target="_blank" className="flex items-center">
                        <div className="p-2">
                            <FaFacebookSquare className="bg-white text-3xl p-1 rounded-full" />
                        </div>
                        <div>
                            <p className="text-white text-md md:block hidden">MaQis Al Hamidy 4</p>
                        </div>
                    </a>
                </a>
            </div>
            <div className="flex bg-black justify-center text-white text-sm p-2">
                <p className="md:text-md text-2xs text-center">Copyright © {year} maQis Al Hamidy 4 x KKNT Inovantren kelompok 2 UPNVJT All rights reserved</p>
            </div>
        </div>
    );
};

export default Footer;

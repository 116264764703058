import React from "react";
import axios from "axios";
import { API_URL } from "../../constant.js";
import { useState, useEffect } from "react";
import Sidebar from "../../components/sidebar";
import Logo from "../../assets/logo.svg";
import { Link } from "react-router-dom";

const Home = () => {
  const [berita, setBerita] = useState([]);
  const [produk, setProduk] = useState([]);

  const fetchProduk = async () => {
    try {
      const response = await axios.get(`${API_URL}/katalog`);
      setProduk(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchBerita = async () => {
    try {
      const response = await axios.get(`${API_URL}/berita`);
      setBerita(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchBerita();
    fetchProduk();
  }, []);

  return (
    <div className="w-full">
      <div className="md:block hidden ">
        <div className="p-16 bg-[#ECECEC] rounded-xl h-[1000px]">
          <h1 className="text-2xl font-bold mb-4">Dashboard</h1>
          <div className="bg-white p-10 rounded-xl flex justify-around items-center">
            <div className="bg-blue-600 w-[200px] h-[160px] text-white rounded-md">
              <div className="h-[20%] items-center flex p-1 px-3 border-b border-blue-800">
                <h1 className="text-xs">Berita</h1>
              </div>
              <div className="h-[50%] p-3 flex justify-center items-center">
                <p className="text-3xl font-semibold">{berita.length}</p>
              </div>
              <div className=" h-[30%] border-t border-blue-800 flex items-center justify-center p-1 px-3 ">
                <Link to={"/AdminMaqis/berita"} class="text-xs bg-blue-800 py-1 px-4 rounded-md ">
                  Lihat Daftar
                </Link>
              </div>
            </div>
            <div className="bg-green-600 w-[200px] h-[160px] text-white rounded-md">
              <div className="h-[20%] items-center flex p-1 px-3 border-b border-green-800">
                <h1 className="text-xs">Produk Swalayan</h1>
              </div>
              <div className="h-[50%] p-3 flex justify-center items-center">
                <p className="text-3xl font-semibold">{produk.length}</p>
              </div>
              <div className="h-[30%] border-t border-green-800 flex items-center justify-center p-1 px-3 ">
                <Link to={"/AdminMaqis/produk"} class="text-xs bg-green-800 py-1 px-4 rounded-md ">
                  Lihat Daftar
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:hidden block p-4 h-[620px]">
        <h1 className="text-2xl font-bold">Dashboard</h1>
        <div className="bg-white p-8 rounded-xl flex justify-around items-center">
          <div className="bg-blue-800 w-[100px] h-[110px] text-white rounded-md">
            <div className="h-[20%] items-center flex p-1 px-3 border-b border-blue-800">
              <h1 className="text-xxs">Berita</h1>
            </div>
            <div className="h-[60%] p-3 flex justify-center items-center">
              <p className="text-3xl font-semibold">{berita.length}</p>
            </div>
            <div className="h-[20%] border-t border-blue-800 flex items-center justify-end p-1 px-3 ">
              <Link to={"/AdminMaqis/berita"} class="text-xs underline">
                Lihat Daftar
              </Link>
            </div>
          </div>
          <div className="bg-green-600 w-[100px] h-[110px] text-white rounded-md">
            <div className="h-[20%] items-center flex p-1 px-3 border-b border-green-800">
              <h1 className="text-xxs">Produk</h1>
            </div>
            <div className="h-[60%] p-3 flex justify-center items-center">
              <p className="text-3xl font-semibold">{produk.length}</p>
            </div>
            <div className="h-[20%] border-t border-green-800 flex items-center justify-end p-1 px-3 ">
              <Link to={"/AdminMaqis/produk"} class="text-xs underline">
                Lihat Daftar
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;

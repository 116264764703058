import React from 'react'
import Hero from '../../components/Profile/Hero'
import Berita from '../../components/Profile/Berita'
import DetailBerita from '../../components/Detail/detailBerita'
import Tentang from '../../components/Profile/Tentang'
import Footer from '../../components/Profile/Footer'
import Navbar from "../../components/Navbar";


const Home = () => {
  return (
    <div className=' bg-[#04535A]'>
        <Navbar />
        <Hero/>
        <Tentang />
        <Berita/>
        <Footer />
        {/* <DetailBerita/> */}
    </div>
  )
}

export default Home
import { useState, useEffect } from "react";
import React from "react";
import axios from "axios";
import { API_URL } from "../../constant.js";
import { FaPlus } from "react-icons/fa";
import { useNavigate, Link } from "react-router-dom";

const Berita = () => {
  const [berita, setBerita] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const totalPages = Math.ceil(berita.length / itemsPerPage);

  const handleClick = (page) => {
    setCurrentPage(page);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = berita.slice(indexOfFirstItem, indexOfLastItem);

  const fetchBerita = async () => {
    try {
      const response = await axios.get(`${API_URL}/berita`);
      setBerita(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Apakah Anda yakin ingin menghapus berita ini?")) {
      try {
        await axios.delete(`${API_URL}/berita/${id}`);
        alert("Berita berhasil dihapus.");
        fetchBerita();
      } catch (error) {
        console.error("Terjadi kesalahan saat menghapus berita:", error);
        alert("Gagal menghapus berita. Silakan coba lagi.");
      }
    }
  };

  const navigate = useNavigate();

  const handleClickNavigate = () => {
    navigate("/AdminMaqis/berita/tambah");
  };

  useEffect(() => {
    fetchBerita();
  }, []);

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + '...';
  };

  return (
    <div className="w-full">
      <div className="p-16 h-[1000px] bg-[#ECECEC] rounded-xl md:block hidden">
        <div className="flex-grow">
          <div className="bg-[#ECECEC] rounded-xl">
            <div className="flex justify-between items-center mb-4">
              <div className="flex">
                <h1 className="text-2xl font-bold">Daftar Berita</h1>
              </div>
              <div
                onClick={handleClickNavigate}
                className="cursor-pointer p-3 bg-[#D9D9D9] rounded-xl text-sm text-white"
              >
                <div className="p-2 bg-primary rounded-lg">
                  <a href="/AdminMaqis/berita/tambah">
                    <FaPlus />
                  </a>
                </div>
              </div>
            </div>
            <div class=" bg-white relative overflow-x-auto shadow-md sm:rounded-lg p-6">
              <div>
                <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
                    <tr>
                      <th scope="col" class="px-6 py-3">
                        No.
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Judul
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Penulis
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Kategori
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Tanggal Dibuat
                      </th>
                      <th scope="col" class="px-6 py-3">
                        <span class="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((berita, index) => (
                      <tr
                        class="bg-white border-b hover:bg-gray-50 "
                        key={index}
                      >
                        <th
                          scope="row"
                          class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                        >
                          {indexOfFirstItem + index + 1}
                        </th>
                        <th
                          scope="row"
                          class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                        >
                          {truncateText(berita.judul, 30)} 
                        </th>
                        <td class="px-6 py-4">{berita.penulis}</td>
                        <td class="px-6 py-4">{berita.kategori}</td>
                        <td class="px-6 py-4">
                          {berita.createdAt.split("T")[0]}
                        </td>
                        <td class="px-6 py-4 text-right flex">
                          <Link
                            to={`/AdminMaqis/berita/update/${berita.id}`}
                            className="pl-3 font-medium text-blue-600 hover:underline"
                          >
                            Edit
                          </Link>
                          <div
                            onClick={() => handleDelete(berita.id)}
                            class=" pl-3 font-medium text-red-600 hover:underline"
                          >
                            Hapus
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex justify-center p-4 mt-4 bg-white">
              <button
                onClick={() => handleClick(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-3 py-1 mx-1 bg-gray-200 rounded disabled:opacity-50 text-xxs"
              >
                Prev
              </button>
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i}
                  onClick={() => handleClick(i + 1)}
                  className={`text-xxs px-3 py-1 mx-1 rounded ${
                    currentPage === i + 1
                      ? "bg-primary text-white"
                      : "bg-gray-200"
                  }`}
                >
                  {i + 1}
                </button>
              ))}
              <button
                onClick={() => handleClick(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="text-xxs px-3 py-1 mx-1 bg-gray-200 rounded disabled:opacity-50"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="md:hidden block">
        <div className="flex-grow">
          <div className="bg-[#ECECEC] rounded-xl p-6">
            <div className="flex justify-between items-center mb-4">
              <div className="flex">
                <h1 className="text-2xl font-bold">Daftar Berita</h1>
              </div>
              <div
                onClick={handleClickNavigate}
                className="cursor-pointer p-3 bg-[#D9D9D9] rounded-xl text-sm text-white"
              >
                <div className="p-2 bg-primary rounded-lg">
                  <a href="/AdminMaqis/berita/tambah">
                    <FaPlus />
                  </a>
                </div>
              </div>
            </div>
            <div class=" bg-white relative overflow-x-auto shadow-md ">
              <div>
                <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
                    <tr>
                      <th scope="col" class="px-6 py-3">
                        No.
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Judul
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Penulis
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Kategori
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Tanggal Dibuat
                      </th>
                      <th scope="col" class="px-6 py-3">
                        <span class="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((berita, index) => (
                      <tr
                        class="bg-white border-b hover:bg-gray-50 "
                        key={index}
                      >
                        <th
                          scope="row"
                          class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                        >
                          {indexOfFirstItem + index + 1}
                        </th>
                        <th
                          scope="row"
                          class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                        >
                          {truncateText(berita.judul, 30)}
                        </th>
                        <td class="px-6 py-4">{berita.penulis}</td>
                        <td class="px-6 py-4">{berita.kategori}</td>
                        <td class="px-6 py-4">
                          {berita.createdAt.split("T")[0]}
                        </td>
                        <td class="px-6 py-4 text-right">
                        <Link
                            to={`/AdminMaqis/berita/update/${berita.id}`}
                            className="pl-3 font-medium text-blue-600 hover:underline"
                          >
                            Edit
                          </Link>
                          <div
                            onClick={() => handleDelete(berita.id)}
                            class=" pl-3 font-medium text-red-600 hover:underline"
                          >
                            Hapus
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex justify-center p-4 mt-4 bg-white">
              <button
                onClick={() => handleClick(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-3 py-1 mx-1 bg-gray-200 rounded disabled:opacity-50 text-xxs"
              >
                Prev
              </button>
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i}
                  onClick={() => handleClick(i + 1)}
                  className={`text-xxs px-3 py-1 mx-1 rounded ${
                    currentPage === i + 1
                      ? "bg-primary text-white"
                      : "bg-gray-200"
                  }`}
                >
                  {i + 1}
                </button>
              ))}
              <button
                onClick={() => handleClick(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="text-xxs px-3 py-1 mx-1 bg-gray-200 rounded disabled:opacity-50"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Berita;

import React, { useState, useEffect, useRef } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const TextEditor = ({ initialContent, onContentChange }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const initialContentLoaded = useRef(false);

  useEffect(() => {
    if (initialContent && !initialContentLoaded.current) {
      const contentBlock = htmlToDraft(initialContent);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        setEditorState(EditorState.createWithContent(contentState));
      }
      initialContentLoaded.current = true;
    }
  }, [initialContent]);

  const onEditorStateChange = (newEditorState) => {
    setEditorState(newEditorState);
    const rawContentState = convertToRaw(newEditorState.getCurrentContent());
    // const htmlContent = draftToHtml(rawContentState);
    let htmlContent = draftToHtml(rawContentState);
    htmlContent = htmlContent.replace(/(?:\r\n|\r|\n)/g, '<p>&nbsp;</p>');

    onContentChange(htmlContent);
  };

  return (
    <div className="mt-6 border bg-white">
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName"
        onEditorStateChange={onEditorStateChange}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'fontFamily',
            'list',
            'textAlign',
            'colorPicker',
            'link',
            'embedded',
            'emoji',
            'image',
            'remove',
            'history'
          ],
          inline: {
            inDropdown: false,
            options: [
              'bold',
              'italic',
              'underline',
              'strikethrough',
              'monospace'
            ]
          },
          blockType: {
            inDropdown: true,
            options: [
              'Normal',
              'H1',
              'H2',
              'H3',
              'H4',
              'H5',
              'H6',
              'Blockquote',
              'Code'
            ]
          },
          fontSize: {
            options: [
              8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96
            ]
          },
          fontFamily: {
            options: [
              'Arial',
              'Georgia',
              'Impact',
              'Tahoma',
              'Times New Roman',
              'Verdana'
            ]
          },
          textAlign: {
            inDropdown: false,
            options: ['left', 'center', 'right', 'justify'],
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
          },
        }}
      />
    </div>
  );
};

export default TextEditor;

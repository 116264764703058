import { useState, useEffect, useCallback } from "react";
import React from "react";
import { FaAnglesDown, FaAnglesUp } from "react-icons/fa6";
import { BsCartPlus } from "react-icons/bs";
import axios from "axios";
import { API_URL } from "../../constant.js";

const Card = ({ search, category }) => {
  const [showAll, setShowAll] = useState(false);
  const [katalog, setKatalog] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const fetchKatalog = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/katalog`, {
        params: { search },
      });
      setKatalog(response.data.data);
    } catch (error) {
      console.error(error);
    }
  }, [search]);

  useEffect(() => {
    fetchKatalog();
  }, [fetchKatalog]);

  const handleBuyClick = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  const googleMapsLink = "https://maps.app.goo.gl/wtNtbY8yKxW6MdoS8";

  return (
    <div className="flex flex-col items-center lg:px-32 sm:px-10 px-4">
      <p className="self-start mt-8 mb-4 md:text-3xl text-lg font-semibold">
        Produk Kami
      </p>
      <div className="grid md:grid-cols-7 grid-cols-3 md:gap-6 gap-2 ">
        {katalog.slice(0, showAll ? katalog.length : 14).map((item, index) => (
          <div
            key={index}
            className="border rounded-md flex flex-col p-2 shadow-sm"
          >
            <img
              className="self-center h-40 object-contain"
              src={item.image_link}
              alt=""
            />
            <p className="md:my-6 my-4 md:text-base text-xs ellipsis text-center flex-grow">
              {item.nama}
            </p>
            <h1 className="font-semibold md:text-xl text-sm">{`Rp ${item.harga}`}</h1>
            <button
              onClick={() => handleBuyClick(item)}
              className="w-full bg-primary flex items-center justify-center gap-2 rounded-md mt-2 text-white md:py-2 py-1 md:text-lg text-xs"
            >
              <BsCartPlus />
              Beli
            </button>
          </div>
        ))}
      </div>
      <div
        className="self-center mt-4 text-hitam px-4 py-2 rounded-md cursor-pointer"
        onClick={() => setShowAll(!showAll)}
      >
        {showAll ? (
          <div className="flex gap-2 items-center text-primary underline">
            Lihat lebih sedikit <FaAnglesUp />
          </div>
        ) : (
          <div className="flex gap-2 items-center text-primary underline">
            Lihat selengkapnya <FaAnglesDown />
          </div>
        )}
      </div>
      {isModalOpen && (
        <div className="z-50 fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded-md shadow-lg w-96">
            <h2 className="text-lg mb-2">Anda bisa beli langsung di</h2>
            <p className="text-xl font-semibold mb-1">MaQis Mart</p>
            <p
              className="text-sm mb-4 flex items-center gap-2"
            >
              Maqis Al-Hamidy 4, Jl Raya Pasean No. KM 02, Batukerbuy, Kec.
              Pasean, Kabupaten Pamekasan, Jawa Timur 69356.
            </p>
            <div>
              <a
                href={googleMapsLink}
                target="_blank"
                rel="noopener noreferrer"
                className="mr-2 bg-primary text-white py-2 px-4 rounded-md"
              >
                Lokasi
              </a>
              <button
                className="bg-red-500 text-white py-2 px-4 rounded-md"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Card;

import imagedummy from '../../../assets/login.png'
import qiroyah from '../../../assets/kamis_legi.jpg'
import kitab from '../../../assets/IMG_20240803_181749.jpg'
import tahfidz from '../../../assets/tahfidz.jpg'
import dirosah from '../../../assets/dirosah.jpg'
import nyolok from '../../../assets/nyolok.jpg'

export const dataProgram = [
    {
      title: "ULUM AL SYARIE (ilmu-ilmu Agama islam)",
      deskripsi:
        ` a. Pengajian kitab kuning kepada pengasuh atau wakilnya<br/>b. Sorogan (santri memperaktekkan baca dan menerangkan kitab dihadapan pengasuh atau wakilnya<br/>c. Praktek ibadah, mudzakaroh adab-adab, dzikir dan hizib<br/>d. Bahasa arab, Membaca, menulis dan muhadatsah percakapan`,
      pic: kitab,
    },
    {
      title: "MAJLIS DIROSAH WAL MUHAWAROH (SEKOLAH)",
      deskripsi:
        "1. Kelas isti'dad/persiapan, program Amtsilati (metode baca kitab kuning aplikatif) <br/>2. Majlis dirasah wal Muhawarah selama enam tahun yang menggabungkan metode pemahaman materi (Dirasah) dan diskusi aktif santri (Muhawarah)",
      pic: dirosah,
    },
    {
        title: "AL QURAN DAN TAHFIDZ",
        deskripsi:
          "a. Membaca Al Quran dengan musyafahah dan talaqqi dihadapan pengasuh <br/>b. Metode tajwid aplikatif dan praktek dalam setiap bacaan (Tahqiq & Tartil) termasuk gharaibul qira-ah dan penentuan waqaf wal ibtida'<br/>c. Murojaah kelompok bagi program tahfidz dengan berkesinambungan dan sistematis<br/>d. Ikhtibar progresif bagi program tahfidz setiap 1 juz, 5 juz, 15 juz dan dinyatakan lulus setelah membaca 30 juz non stop dihadapan lajnah dan pengasuh",
        pic: tahfidz,
      },
    {
      title: "PROGRAM SANTRI KHORIJI (Santri nyulok)",
      deskripsi:
        "1. KELAS ANAK-ANAK<br/>a. Qiroah Al Quran dan tahfidz Quran dengan metode talaqqi syafawi dengan ilmu tajwid aplikatif (Tahqiq & Tartil) <br/>b. Pengajian kitab kuning <br/>c. Majlis dirasah ilmu agama<br/>2. KELAS UMUM (Semua Usia)<br/>a. Tahqiq dan tahfidz Al Quran <br/>b. Jadwal menyesuaikan",
      pic: nyolok,
    },
    {
      title: "DAKWAH DAN SOSIAL KEMASYARAKATAN",
      deskripsi:
        "a. Qiroyah Rutin malam kamis legi berupa khotmil Quran, istighatsah dan konsultasi (tanya jawab agama  <br/>b. Santri Kilat: program khusus liburan sekolah umum berupa kursus bahasa arab, gharoibul Qiroah dan siroh Nabawi",
      pic: qiroyah,
    },
  ];
import React, {useState} from 'react'
import { useEffect } from 'react';
import Header from '../../components/Swalayan/Header';
import Swiper from '../../components/Swalayan/Swiper';
import Card from '../../components/Swalayan/Card';
import Navbar from "../../components/Navbar";
import Footer from '../../components/Profile/Footer';

const Swalayan = () => {

  const [searchTerm, setSearchTerm] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  return (
    <div>
        <Navbar />
        <Header setSearchTerm={setSearchTerm} />
        <div className="md:block hidden">
        <Swiper slide={2}  />
        </div>
        <div className="md:hidden block">
        <Swiper slide={1}  />
        </div>
        <Card search={searchTerm} />
        <Footer/>
    </div>
  )
}

export default Swalayan
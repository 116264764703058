import React from "react";
import { SiGooglemaps } from "react-icons/si";
import { FaPaperPlane } from "react-icons/fa";

const Hero = () => {
  return (
    <div className="w-full md:h-[940px] h-[570px] rounded-b-3xl bg-[#F8A65C]">
      <div className="w-full md:h-[900px] h-[538px] bg-cover bg-center bg-desktop bg-mobile flex flex-col items-center md:items-start md:pl-24 md:text-left text-center rounded-b-3xl shadow-lg">
        <div className="mt-20 md:mt-40 flex flex-col items-center md:items-start">
          <div
            className="bg-secondary inline-block items-center gap-2 mb-2 md:font-bold font-medium px-4 py-2 rounded-full text-white md:text-2xl "
            data-aos="fade-right"
            data-aos-once="true"
          >
            <a
              href="https://maps.app.goo.gl/wtNtbY8yKxW6MdoS8"
              target="_blank"
              rel="noreferrer"
              className="gap-2 items-center text-2xl md:flex hidden"
            >
              <SiGooglemaps />
              Pesantren maQis Al Hamidy 4
            </a>
            <a
              href="https://maps.app.goo.gl/wtNtbY8yKxW6MdoS8"
              target="_blank"
              rel="noreferrer"
              className="flex gap-2 items-center md:hidden text-sm"
            >
              <SiGooglemaps />
              Pesantren maQis Al Hamidy 4
            </a>
          </div>
          <p
            className="font-medium md:text-2xl text-md text-primary leading-normal"
            data-aos="zoom-out"
            data-aos-once="true"
          ></p>
          <h1
            className="font-bold text-primary md:text-2xl text-xs leading-snug"
            data-aos="fade-up"
            data-aos-once="true"
          >
            Lembaga pendidikan yang berikhtiyar mencetak <br />
          </h1>
          <h1
            className="font-semibold md:text-6xl text-2xl text-hitam leading-snug"
            data-aos="fade-up"
            data-aos-once="true"
          >
            Generasi{" "}
            <span className="font-bold text-2xl md:text-6xl">Qurany</span>,{" "}
            <br />
            Karakter{" "}
            <span className="font-bold text-2xl md:text-6xl">Nabawy</span>
          </h1>
          <a
            href="/#tentang"
            className="bg-primary md:px-8 px-4 mt-4 md:py-4 py-2 rounded-3xl flex md:gap-4 gap-2 items-center text-white text-md md:text-xl"
            data-aos="zoom-in"
            data-aos-once="true"
          >
            <FaPaperPlane className="block md:hidden" size={15} />
            <FaPaperPlane className="hidden md:block" size={25} />
            Telusuri
          </a>
        </div>
      </div>
    </div>
  );
};

export default Hero;

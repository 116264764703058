import React from "react";

function Programcard({ setOnHover, service, onHover }) {
  return (
    <>
      <div
        onMouseEnter={() => setOnHover(service.title)}
        onMouseLeave={() => setOnHover("")}
        className="relative max-w-[400px] sm:w-[400px] lg:w-[400px] h-[268px] lg:h-[516px] shrink-0 rounded-[10px] overflow-hidden cursor-pointer"
      >
        <div
          className={`${
            onHover === service.title
              ? "bg-black bg-opacity-50"
              : "bg-black bg-opacity-0"
          } absolute top-0 left-0 w-full h-[268px] lg:h-full rounded-[10px] z-20`}
        ></div>
        <img
          src={service.pic}
          alt="service"
          className={` relative h-full w-full object-cover rounded-[10px]`}
        />
        <div className={`${
              onHover === service.title ?" bg-[#f5f5f5]" : "bg-inherit"} absolute bottom-0  w-full md:h-[330px] h-[200px] rounded-b-[10px] z-30 overflow-hidden`}>
          <div
            className={`${
              onHover === service.title ? "translate-y-0" : "md:translate-y-[230px] translate-y-[150px] "
            } absolute   w-full bg-[#f5f5f5] p-[20px]   duration-300 z-40`}
          >
            <p className="md:text-[22px] text-[12px] font-medium md:leading-[32px] leading-[12px] md:mb-[16px] mb-2 text-primary text-center">
              {service.title}
            </p>

            <p
  className={`${
    onHover === service.title ? "opacity-100" : "opacity-0"
  } md:text-[16px] text-[10px] font-light md:leading-[24px] leading-[16px] text-whiteSmoke600`}
  dangerouslySetInnerHTML={{ __html: service.deskripsi.replace(/\n/g, '&nbsp;') }}
></p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Programcard;

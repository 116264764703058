import React, { useState, useRef } from "react";
import axios from "axios";
import { API_URL } from "../../constant.js";
import TextEditor from "../../components/textEditor.jsx";
import { useNavigate } from "react-router-dom";

const Berita = () => {
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    gambarBerita: null,
    gambarFile: null,
    judul: "",
    penulis: "",
    konten: "",
    kategori: "Dakwah",
  });

  const navigate = useNavigate();

  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleContent = (newContent) => {
    setFormData({
        ...formData,
        konten: newContent,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const imageDataUrl = event.target.result;
        setFormData({
          ...formData,
          gambarBerita: imageDataUrl,
          gambarFile: file,
        });
      };

      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);


    const productData = new FormData();
    productData.append("judul", formData.judul);
    productData.append("penulis", formData.penulis);
    productData.append("konten", formData.konten);
    productData.append("kategori", formData.kategori);

    if (formData.gambarFile) {
      productData.append("gambarBerita", formData.gambarFile);
    }

    try {
      const response = await axios.post(`${API_URL}/berita`, productData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setFormData({
        gambarBerita: null,
        judul: "",
        penulis: "",
        konten: "",
        kategori: "Dakwah",
      });

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      navigate('/AdminMaqis/berita');
    }
  };

  return (
    <div className="w-full h-[800px]">
      <div className="p-10">
        <h1 className="text-xl font-bold mb-5">Tambah Berita</h1>
        <div className="mb-4">
          <form onSubmit={handleSubmit}>
            <label className="block text-black font-heavy">Gambar Berita</label>
            {formData.gambarBerita && (
              <img
                src={formData.gambarBerita}
                alt="Product"
                className="mt-4 h-32 object-cover"
              />
            )}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              ref={fileInputRef}
              className="mt-1 p-2 border rounded w-full"
            />
            <div className="mb-4 mt-2">
              <h1>Judul Berita</h1>
              <input
                type="text"
                name="judul"
                value={formData.judul}
                onChange={handleChange}
                className="mt-2 p-2 border rounded w-full"
                required
              />
            </div>
            <div className="mb-4 mt-2">
              <h1>Penulis</h1>
              <input
                type="text"
                name="penulis"
                value={formData.penulis}
                onChange={handleChange}
                className="mt-2 p-2 border rounded w-full"
                required
              />
            </div>
            <div>
              <h1>Kategori</h1>
              <div className="mb-4">
                <select
                  name="kategori"
                  value={formData.kategori}
                  onChange={handleChange}
                  className="mt-2 p-2 border rounded w-full"
                  required
                >
                  <option value="Dakwah">Dakwah</option>
                  <option value="Enterpreneur">Enterpreneur</option>
                  <option value="Gadget">Gadget</option>
                  <option value="Keagamaan">Keagamaan</option>
                  <option value="KKN">KKN</option>
                  <option value="Kesehatan">Kesehatan</option>
                  <option value="Kesenian">Kesenian</option>
                  <option value="Kuliner">Kuliner</option>
                  <option value="Kebersihan">Kebersihan</option>
                  <option value="Otomotif">Otomotif</option>
                  <option value="Olahraga">Olahraga</option>
                  <option value="Pendidikan">Pendidikan</option>
                  <option value="Sosial">Sosial</option>
                </select>
              </div>
            </div>
            <div className="rounded-lg">
              <TextEditor initialContent={formData.konten} onContentChange={handleContent}/>
            </div>
            <button
              type="submit"
              className="bg-blue-500 text-white p-2 rounded mt-4"
              disabled={loading}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Berita;
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { BsDot } from "react-icons/bs";
import Navbar from "../Navbar";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../constant";
import Footer from "../Profile/Footer";

const DetailBerita = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [berita, setBerita] = useState([]);
  const [beritas, setBeritas] = useState([]);
  const truncateText = (text, maxSentences) => {
    const sentences = text.split(".");
    const truncated =
      sentences.slice(0, maxSentences).join(".") +
      (sentences.length > maxSentences ? "..." : "");
    return truncated;
  };

  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const fetchBerita = async () => {
    try {
      const response = await axios.get(`${API_URL}/berita/${id}`);
      setBerita(response.data.data[0]);
    } catch (error) {}
  };
  const fetchBeritas = async () => {
    try {
      const response = await axios.get(`${API_URL}/berita`);
      setBeritas(response.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchBerita();
    fetchBeritas();
  }, [id]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const filteredBeritas = beritas.filter(
    (berita) => berita.id !== parseInt(id)
  );
  const displayedBeritas = filteredBeritas.reverse().slice(0, 3);

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("id-ID", options);
  };

  const formatTime = (isoDate) => {
    const date = new Date(isoDate);
    const options = {
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleTimeString("id-ID", options);
  };

  const handleBeritaLain = (beritaId) => {
    navigate(`/detail/${beritaId}`);
  };

  return (
    <div className="flex flex-col">
      <Navbar />
      <h1 className="font-bold md:text-3xl text-md text-black md:pl-12 px-6 mt-5">
        {berita.judul}
      </h1>
      <div className="flex text-sm text-gray-500 md:pl-12 px-6 mt-2">
        {/* <span>{`Penulis: ${berita.penulis}`}</span> */}
        <span className="font-semibold pr-2">{berita.penulis}</span>
        <span className="pl-2">{formatDate(berita.createdAt)}</span>
        <span className="pl-2">{formatTime(berita.createdAt)}</span>
      </div>
      <div className="mx-6 md:ml-12 border rounded-lg p-1 text-xs w-24 text-center bg-[#F8A65C] mt-2 mb-5">
        <h1>{berita.kategori}</h1>
      </div>
      <div className="md:h-[400px] h-[200px] relative rounded-xl">
        <img
          src={berita.image_link}
          alt=""
          className="absolute top-0 left-0 px-12 md:object-contain object-cover md:h-full h-[200px]"
        />
      </div>
      <div className="pl-12 pr-12 mt-5 mb-5">
        <div className="flex justify-between">
          <div className="md:w-[77%]">
            <p className="md:text-md text-sm">
              <div dangerouslySetInnerHTML={{ __html: berita.konten }} />
            </p>
          </div>
          <div className="mt-5 w-[22%] md:block hidden cursor-pointer">
            <h1 className="font-bold text-xl mb-3">Berita Lain</h1>
            {displayedBeritas.map((berita, index) => (
              <div
                className="border rounded-lg p-1 shadow-md mb-4 "
                key={index}
                onClick={() => handleBeritaLain(berita.id)}
              >
                <div className="flex justify-center items-stretch mb-1 cursor-pointer">
                  <div className="flex w-[45%] object-cover items-stretch justify-center">
                    <img
                      src={berita.image_link}
                      className="object-cover w-full h-full rounded"
                      alt=" "
                    />
                  </div>
                  <div className="w-1/2 justify-center items-center p-2">
                    <h1 className="font-bold text-xs">{berita.judul}</h1>
                  </div>
                </div>
                <div>
                  <p className="text-xxs p-1">
                    {truncateText(stripHtmlTags(berita.konten), 2)}
                  </p>
                </div>
                <div className="flex pl-1">
                  <a className="text-xxs text-primary">{berita.kategori}</a>
                  <BsDot />
                  <p className="text-xxs">{formatDate(berita.createdAt)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DetailBerita;

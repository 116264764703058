import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import busana from '../../assets/busana.jpg'
import umroh from '../../assets/umroh.jpg'
import perlengkapan from '../../assets/perlengkapan.jpg'
import jajan from '../../assets/bannerwebbelanja.jpg'

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import '../../Swalayan.css';

const Swiperr = ({slide}) => {
  return (
    <Swiper
      modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
      spaceBetween={5}
      slidesPerView={slide}
      pagination={{ clickable: true }}
      autoplay={{
        delay: 3000, 
        disableOnInteraction: false, 
      }}
    >
      <SwiperSlide>
        <div className='md:p-6 p-2 md:mb-4 mb-6'><img className="md:rounded-3xl rounded-xl" src={jajan} alt="" /></div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='md:p-6 p-2 md:mb-4  mb-6'><img className="md:rounded-3xl rounded-md" src={perlengkapan} alt="" /></div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='md:p-6 p-2 md:mb-4 mb-6'><img className="md:rounded-3xl rounded-md" src={busana} alt="" /></div>
      </SwiperSlide>
      <SwiperSlide>
        <div className='md:p-6 p-2 md:mb-4 mb-6'><img className="md:rounded-3xl rounded-md" src={umroh} alt="" /></div>
      </SwiperSlide>
      
    </Swiper>
  );
};

export default Swiperr

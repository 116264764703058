import { useState, useEffect } from "react";
import React from "react";
import Logo from "../../assets/logo.svg";
import axios from "axios";
import { API_URL } from "../../constant.js";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const Produk = () => {
  const [produk, setProduk] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const totalPages = Math.ceil(produk.length / itemsPerPage);

  const handleClick = (page) => {
    setCurrentPage(page);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = produk.slice(indexOfFirstItem, indexOfLastItem);

  const fetchProduk = async () => {
    try {
      const response = await axios.get(`${API_URL}/katalog`);
      setProduk(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const navigate = useNavigate();

  const handleClickNavigate = () => {
    navigate("/AdminMaqis/produk/tambah");
  };

  const handleClickNavigateUpdate = (id) => {
    navigate(`/AdminMaqis/produk/update/${id}`);
  };

  const handleDelete = async (id) => {
    if (window.confirm("Apakah Anda yakin ingin menghapus produk ini?")) {
      try {
        await axios.delete(`${API_URL}/katalog/${id}`);
        alert("Produk berhasil dihapus.");
        fetchProduk();
      } catch (error) {
        console.error("Terjadi kesalahan saat menghapus produk:", error);
        alert("Gagal menghapus produk. Silakan coba lagi.");
      }
    }
  };

  useEffect(() => {
    fetchProduk();
  }, []);

  return (
    <div className="w-full">
      <div className="md:block hidden">
        <div className="p-10  w-full h-[1000px] bg-[#ECECEC] rounded-xl">
          <div className="flex-grow">
            <div className="bg-[#ECECEC] rounded-xl">
              <div className="flex justify-between items-center mb-2">
                <div className="">
                  <h1 className="text-2xl font-bold">Daftar Produk</h1>
                </div>
                <div
                  onClick={handleClickNavigate}
                  className="cursor-pointer p-3 bg-[#D9D9D9] rounded-xl text-sm text-white"
                >
                  <div className="p-2 bg-primary rounded-lg">
                    <a href="/AdminMaqis/produk/tambah">
                      <FaPlus />
                    </a>
                  </div>
                </div>
              </div>
              <div class=" bg-white relative overflow-x-auto shadow-md sm:rounded-lg p-6">
                <div>
                  <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
                      <tr>
                        <th scope="col" class="px-6 py-3">
                          No.
                        </th>
                        <th scope="col" class="px-6 py-3">
                          Nama
                        </th>
                        <th scope="col" class="px-6 py-3">
                          Harga
                        </th>
                        <th scope="col" class="px-6 py-3">
                          Kategori
                        </th>
                        <th scope="col" class="px-6 py-3">
                          Tanggal Dibuat
                        </th>
                        <th scope="col" class="px-6 py-3">
                          <span class="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems.map((produk, index) => (
                        <tr class="bg-white border-b hover:bg-gray-50 ">
                          <th
                            scope="row"
                            class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                          >
                            {indexOfFirstItem + index + 1}
                          </th>
                          <th
                            scope="row"
                            class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
                          >
                            {produk.nama}
                          </th>
                          <td class="px-6 py-4">{produk.harga}</td>
                          <td class="px-6 py-4">{produk.kategori}</td>
                          <td class="px-6 py-4">
                            {produk.createdAt.split("T")[0]}
                          </td>
                          <td class="px-6 py-4 text-right">
                            <a
                              onClick={() =>
                                handleClickNavigateUpdate(produk.id)
                              }
                              href=""
                              class="font-medium text-blue-600 hover:underline"
                            >
                              Edit
                            </a>
                            <a
                              onClick={() => handleDelete(produk.id)}
                              href="#"
                              class=" pl-3 font-medium text-red-600 hover:underline"
                            >
                              Hapus
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="flex justify-center p-4 mt-4 bg-white">
                <button
                  onClick={() => handleClick(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="px-3 py-1 mx-1 bg-gray-200 rounded disabled:opacity-50 text-xxs"
                >
                  Prev
                </button>
                {Array.from({ length: totalPages }, (_, i) => (
                  <button
                    key={i}
                    onClick={() => handleClick(i + 1)}
                    className={`text-xxs px-3 py-1 mx-1 rounded ${
                      currentPage === i + 1
                        ? "bg-primary text-white"
                        : "bg-gray-200"
                    }`}
                  >
                    {i + 1}
                  </button>
                ))}
                <button
                  onClick={() => handleClick(currentPage + 1)}
                  disabled={currentPage === totalPages}
                  className="text-xxs px-3 py-1 mx-1 bg-gray-200 rounded disabled:opacity-50"
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="md:hidden block">
        <div className="flex-grow">
          <div className="bg-[#ECECEC] rounded-xl p-6">
            <div className="flex justify-between items-center mb-4">
              <div className="flex">
                <h1 className="text-2xl font-bold">Daftar Produk</h1>
              </div>
              <div
                onClick={handleClickNavigate}
                className="cursor-pointer p-3 bg-[#D9D9D9] rounded-xl text-sm text-white"
              >
                <div className="p-2 bg-primary rounded-lg">
                  <a href="/AdminMaqis/produk/tambah">
                    <FaPlus />
                  </a>
                </div>
              </div>
            </div>
            <div class=" bg-white relative overflow-x-auto shadow-md">
              <div>
                <table class="w-full text-sm text-left rtl:text-right text-gray-500">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 ">
                    <tr>
                      <th scope="col" class="px-6 py-3">
                        No.
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Nama
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Harga
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Kategori
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Tanggal Dibuat
                      </th>
                      <th scope="col" class="px-6 py-3">
                        <span class="sr-only">Edit</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((produk, index) => (
                      <tr
                        className="bg-white border-b hover:bg-gray-50"
                        key={indexOfFirstItem + index}
                      >
                        <th
                          scope="row"
                          className="md:px-6 md:py-4 py-3 px-2 font-medium text-gray-900 whitespace-nowrap"
                        >
                          {indexOfFirstItem + index + 1}
                        </th>
                        <th
                          scope="row"
                          className="md:px-6 md:py-4 py-3 px-2 font-medium text-gray-900 whitespace-nowrap"
                        >
                          {produk.nama}
                        </th>
                        <td className="md:px-6 md:py-4 py-3 px-2">
                          {produk.harga}
                        </td>
                        <td className="md:px-6 md:py-4 py-3 px-2">
                          {produk.kategori}
                        </td>
                        <td className="md:px-6 md:py-4 py-3 px-2">
                          {produk.createdAt.split("T")[0]}
                        </td>
                        <td className="md:px-6 md:py-4 py-3 px-2 text-right">
                          <div
                            onClick={() => handleClickNavigateUpdate(produk.id)}
                            className="font-medium text-blue-600 hover:underline"
                          >
                            Edit
                          </div>
                          <div
                            onClick={() => handleDelete(produk.id)}
                            className="pl-3 font-medium text-red-600 hover:underline"
                          >
                            Hapus
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex justify-center p-4 mt-4 bg-white">
              <button
                onClick={() => handleClick(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-3 py-1 mx-1 bg-gray-200 rounded disabled:opacity-50 text-xxs"
              >
                Prev
              </button>
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i}
                  onClick={() => handleClick(i + 1)}
                  className={`text-xxs px-3 py-1 mx-1 rounded ${
                    currentPage === i + 1
                      ? "bg-primary text-white"
                      : "bg-gray-200"
                  }`}
                >
                  {i + 1}
                </button>
              ))}
              <button
                onClick={() => handleClick(currentPage + 1)}
                disabled={currentPage === totalPages}
                className="text-xxs px-3 py-1 mx-1 bg-gray-200 rounded disabled:opacity-50"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Produk;

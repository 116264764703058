import React from "react";
import Sidebar from "../../components/sidebar";
import Berita from '../../components/Admin/berita'

const Admin = () => {
  return (
    <div className="md:ml-64 bg-[#ECECEC] md:flex">
      <Sidebar />
      <Berita />
    </div>
  );
};

export default Admin;

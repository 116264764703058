import React, { useState } from "react";
import { BiCategory } from "react-icons/bi";
import { IoMdSearch } from "react-icons/io";

const Header = ({ setSearchTerm }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [input, setInput] = useState("");

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleSearchChange = (event) => {
    setInput(event.target.value);
  };

  const handleSearchClick = () => {
    setSearchTerm(input);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSearchClick();
    }
  };

  const handleCategorySelect = (category) => {
    setSearchTerm(category);
    setInput(category);
    setDropdownVisible(false);
  };

  return (
    <div className="flex gap-4 bg-[#f5f5f5] lg:px-32 px-8 py-3 items-center justify-between">
      <div>
        <div
          className="flex gap-2 items-center md:text-xl text-sm cursor-pointer"
          onClick={toggleDropdown}
        >
          <BiCategory className="md:block hidden" size={30} />
          <BiCategory className="md:hidden block" size={20} />
          kategori
        </div>

        <div
          id="dropdown"
          className={`absolute z-10 ${
            dropdownVisible ? "block" : "hidden"
          } bg-[#f5f5f5] dark:bg-[#2D2D2D] divide-y divide-gray-100 md:px-4 rounded-lg `}
        >
          <ul
            className="py-2 text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownDefaultButton"
          >
            {[
              "Makanan",
              "Minuman",
              "Pakaian",
              "Pertanian",
              "Produk Kebersihan",
              "Produk Kesehatan",
              "Alat Tulis",
              "Alat Kebersihan",
              "Perlengkapan",
            ].map((category) => (
              <li key={category}>
                <a
                  href="#"
                  className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={() => handleCategorySelect(category)}
                >
                  {category}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="relative xl:w-[550px] lg:w-[450px] w-[200px] border p-1 rounded-3xl flex gap-2 bg-[#D9D9D9]">
        <input
          className="text-black md:text-base md:w-full w-[100px] text-xs font-[300] flex-grow ml-4 focus:outline-none bg-inherit"
          type="text"
          placeholder="Cari Produk"
          value={input}
          onChange={handleSearchChange}
          onKeyPress={handleKeyPress}
        />
        <div className="flex items-center gap-2">
          <button
            onClick={handleSearchClick}
            className="bg-primary md:px-4 px-2 md:py-2 py-1 rounded-3xl flex justify-between md:gap-5 gap-2 items-center"
          >
            <IoMdSearch className="hidden md:block" size={20} color="white" />
            <IoMdSearch className="md:hidden block" size={12} color="white" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;

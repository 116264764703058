import React from "react";
import { useState, useCallback, useEffect } from "react";
import Logo from "../assets/logo.svg";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { LuLayoutDashboard } from "react-icons/lu";
import { FaRegNewspaper } from "react-icons/fa";
import { MdProductionQuantityLimits } from "react-icons/md";
import { BiLogOut } from "react-icons/bi";


const Sidebar = () => {
  const [nav, setNav] = useState(false);
  const handleNav = () => {
    setNav(!nav);
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/loginmaqis'); // atau rute halaman login Anda
  };

  const handleResize = useCallback(() => {
    if (window.innerWidth >= 768) {
      setNav(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return (
    <div className="">
      <div className="pl-2 pr-4 pt-4 fixed top-0 left-0 h-full w-64 bg-[#042E32] text-white shadow-lg md:flex hidden flex-col justify-between">
        <div>
        <div className="flex justify-center">
          <img className="hidden md:block" src={Logo} alt="Logo" width={80} height={87}></img>
        </div>
        <h2 className="text-xl font-semibold mt-4 mb-2">Pesantren maQis Al Hamidy 4</h2>
        <hr />
        <ul className="">
          <li className="mt-2 flex items-center gap-1 hover:bg-[#13545B] py-2 pl-2 pr-4 rounded-lg">
            <LuLayoutDashboard/><a href="/AdminMaqis">Dashboard</a>
          </li>
          
          <li className="mt-2 flex items-center gap-1 hover:bg-[#13545B] py-2 pl-2 pr-4 rounded-lg">
            <FaRegNewspaper/><a href="/AdminMaqis/berita">Berita</a>
          </li>
          <li className="mt-2 flex items-center gap-1 hover:bg-[#13545B] py-2 pl-2 pr-4 rounded-lg">
            <MdProductionQuantityLimits/><a href="/AdminMaqis/produk">Produk</a>
          </li>
        </ul>
        </div>
        <div onClick={handleLogout} className="cursor-pointer self-center flex items-center gap-2 py-2 px-8 rounded-3xl bg-[#E83D3D] mb-16">
        <BiLogOut/>Log out
      </div>
      </div>
      
      <div className="md:hidden block p-4 bg-[#042E32] flex justify-between items-center">
        <img className="ml-8" src={Logo} alt="Logo" width={60}></img>
        <div onClick={handleNav} className="text-white block md:hidden mr-8">
          <AiOutlineMenu size={20} />
        </div>
      </div>
      <div
          className={
            nav
              ? "fixed z-50 top-0 right-0 w-[60%] h-full border-r-gray-900 bg-[#042E32] ease-in-out duration-500"
              : "fixed z-50 top-0 right-[-100%] w-[60%] h-full border-r-gray-900 bg-[#042E32] ease-in-out duration-500"
          }
        >

          <div onClick={handleNav} className="flex justify-end mr-8 mt-6 p-4 md:hidden text-white">
            <AiOutlineClose size={20} />
          </div>

          <div className="flex flex-col">
          <ul className="uppersize py-4 text-white">
            <li className="p-4 border-b border-gray-600">
              <Link to="/AdminMaqis">Beranda</Link>
            </li>
            <li className="p-4 border-b border-gray-600"><Link to="/AdminMaqis/berita">Berita</Link></li>
            <li className="p-4 border-b border-gray-600"><Link to="/AdminMaqis/produk">Produk</Link></li>
          </ul>
          <div onClick={handleLogout} className=" cursor-pointer self-center flex items-center gap-2 py-1 px-4 rounded-2xl bg-[#E83D3D] mt-32 text-white">
        <BiLogOut/>Log out
          </div>
          </div>
        </div>
    </div>
  );
};

export default Sidebar;

import React, { useState, useRef } from "react";
import axios from "axios";
import { API_URL } from "../../constant.js";
import { useNavigate } from "react-router-dom";

const Produk = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    gambarKatalog: null,
    gambarFile: null,
    nama: "",
    harga: "",
    kategori: "Makanan",
  });

  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const imageDataUrl = event.target.result;
        setFormData({
          ...formData,
          gambarKatalog: imageDataUrl,
          gambarFile: file,
        });
      };

      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);


    const productData = new FormData();
    productData.append("nama", formData.nama);
    productData.append("harga", formData.harga);
    productData.append("kategori", formData.kategori);

    if (formData.gambarFile) {
      productData.append("gambarKatalog", formData.gambarFile);
    }

    try {
      const response = await axios.post(`${API_URL}/katalog`, productData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setFormData({
        gambarKatalog: null,
        nama: "",
        harga: "",
        kategori: "Makanan",
      });

      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
      navigate('/AdminMaqis/produk');
    }
  };

  return (
    <div className="w-full h-[800px]">
      <div className="p-10">
        <h1 className="text-xl font-bold mb-5">Tambah Produk</h1>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label className="block text-black font-heavy">
                Gambar Produk
              </label>
              {formData.gambarKatalog && (
                <img
                  src={formData.gambarKatalog}
                  alt="Product"
                  className="mt-4 h-32 object-cover"
                />
              )}
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                ref={fileInputRef}
                className="mt-2 p-2 border rounded w-full"
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Nama Produk</label>
              <input
                type="text"
                name="nama"
                value={formData.nama}
                onChange={handleChange}
                className="mt-2 p-2 border rounded w-full"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Harga Produk</label>
              <input
                type="number"
                name="harga"
                value={formData.harga}
                onChange={handleChange}
                className="mt-2 p-2 border rounded w-full"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700">Kategori Produk</label>
              <select
                name="kategori"
                value={formData.kategori}
                onChange={handleChange}
                className="mt-2 p-2 border rounded w-full"
                required
              >
                <option value="Makanan">Makanan</option>
                <option value="Minuman">Minuman</option>
                <option value="Pakaian">Pakaian</option>
                <option value="Pertanian">Pertanian</option>
                <option value="Produk Kebersihan">Produk Kebersihan</option>
                <option value="Produk Kesehatan">Produk Kesehatan</option>
                <option value="Alat Tulis">Alat Tulis</option>
                <option value="Alat Kebersihan">Alat Kebersihan</option>
                <option value="Perlengkapan">Perlengkapan</option>
              </select>
            </div>
            <button
              type="submit"
              className="bg-blue-500 text-white p-2 rounded"
              disabled={loading}
            >
              {loading ? "Submitting..." : "Submit"}
            </button>
          </form>
      </div>
    </div>
  );
};

export default Produk;

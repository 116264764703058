import React from "react";
import banner from "../../assets/banner-1.png";
import background from "../../assets/backgound_pengasuh.png";
import foto from "../../assets/kiyai.png";
import Program from "./Program";
import Fasilitas from './Fasilitas'
import Penerimaan from './Penerimaan'

const MissionList = () => {
  return (
    <div className="p-4 py-1">
      <div className="grid grid-cols-1 md:grid-cols-[1fr_auto_1fr] md:gap-8 px-4">
        <div className="mb-2">
          <ol className="list-decimal space-y-2 md:text-md lg:text-lg text-sm">
            <li>
              Menyiapkan generasi mandiri, tangguh yang siap menjadi penyambung
              lidah Rasulullah SAW didalam pendidikanis berkesinambungan dengan
              sanad yang tersambung kepada Rasulullah SAW
            </li>
            <li>
              Melatih dan membiasakan santri beribadah dan berakhlak sesuai
              sunnah Rasulullah SAW dan adab ulama sholihin sejak bangun tidur
              hingga tidur kembali
            </li>
            <li>
              Mengajarkan ilmu agama yang tersambung dengan Rasulullah SAW
              dengan metode bandongan, secara sistematis
            </li>
          </ol>
        </div>
        <div className="hidden md:block border-l-2 border-primary h-auto"></div>
        <div className="">
          <ol
            start="4"
            className="list-decimal space-y-2 md:text-md lg:text-lg text-sm"
          >
            <li>
              Mengajarkan pemahaman kitab kuning dan percakapan bahasa arab
              sebagai modal awal memahami literatur kitab kitab ilmu agama
            </li>
            <li>
              Menyiapkan generasi mandiri, tangguh yang siap menjadi penyambung
              lidah Rasulullah SAW didalam pendidikan dan dakwah
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

const Tentang = () => {
  return (
    <div id="tentang">
      <div className="flex items-center md:mt-1 mt-1 justify-center">
        <div className="center -mt-7 -mb-16 md:-mb-4">
          <div className=" text-black font-bold bg-[#F8A65C] px-2 md:text-2xl text-md -mt-8 md:mt-0 ">
            Tentang Kami
          </div>
        </div>
      </div>

      <div className="bg-[#F8A65C] rounded-3xl pt-8 md:pb-10 pb-3">
        <div className=" rounded-3xl bg-white shadow-xl ">
          <div className=" md:h-[300px] h-[140px] mb-6 rounded-t-3xl " >
            <img
              src={banner}
              className="md:w-full h-full object-cover rounded-t-3xl shadow-top"
              alt=""
              data-aos="zoom-in"
              data-aos-once="true"
            />
          </div>
          <div className="px-12">
            <p className="md:text-lg text-sm text-justify" data-aos="fade-up"
              data-aos-once="true">
              Ma’hadu Al- Qur’an Wal ilmi Syar’i Pesantren MaQis Al-Hamidy 4
              memulai kegiatan belajar mengajar pada tanggal 1 ramadhan 1439
              Hijriyyah atau  tanggal 17 mei tahun 2018 dan di resmikan oleh
              khodimul ma’ahid Al-Hamidy yaitu Raden Kiyai Haji Muhammad Rofi’ie
              Baidlawi pada tanggal 7 Desember tahun 2018 sebagai bagian dari
              ma’ahid Al-Hamidy bayuanyar. Secara umum MaQis Al-Hamidy 4 punya
              misi dan tujuan besar, Pertama adalah menciptakan
              generasi-generasi yang hafal Al-Qur’an dan menjaga kemurnian
              lafadz Al-Qur’an, dan yang ke dua adalah menjaga pemahaman tentang
              Al-Qur’an lewat para ulama yang sanad keilmuannya tersambung
              sampai Rasulullah. Maka Pesantren MaQis Al-Hamidy 4 mempunyai 2
              program yaitu Ma’hadiyah yang berupa tahfidz Al-Qur’an dan kajian
              kitab kuning, kemudian Dirosiah yang berupa majelis Dirosah Wal
              Muhawaroh.
            </p>
          </div>
          <div>
            <div className="flex items-center justify-center mt-16 mb-8">
              <span className="flex-shrink mx-4 bg-primary px-8 py-1 rounded-full text-white font-bold">
                Visi
              </span>
            </div>
            <div className="md:px-32 lg:px-64 px-8" data-aos="zoom-in"
              data-aos-once="true">
              <p className="text-center md:text-lg text-sm">
                Menjadi lembaga pendidikan dan dakwah yang menyampaikan ilmu
                agama islam ala manhaj salafus sholih sehingga menciptakan
                Generasi Qurany karakter Nabawy yang hafal Al-Quran, Faham
                Al-Quran, amalkan Al-Quran, berakhlak Al-Quran dan mendakwahkan
                Al-Quran
              </p>
            </div>
          </div>

          <div className="rounded-b-3xl">
            <div className="flex items-center justify-center mt-16 mb-8">
              <span className="flex-shrink mx-4 bg-primary px-8 py-1 rounded-full text-white font-bold">
                Misi
              </span>
            </div>
            <div className="flex justify-center " data-aos="zoom-in"
              data-aos-once="true">
              <div className="container ml-8 mr-8">
                <MissionList />
              </div>
            </div>
            <div className="p-2 bg-red mt-10"></div>
          </div>

          <div className="md:pb-16">
            <div className="w-full flex flex-col items-center justify-center md:mt-8 mb-8">
              <span className="flex-shrink mx-4 bg-primary px-8 py-2 rounded-full text-white font-bold md:text-xl">
                Profil Pengasuh
              </span>
              <div className="container md:mt-20 mt-8 mb-8 p-8 " data-aos="fade-up"
                data-aos-once="true">
                <div className="w-full flex ">
                  <div
                    className="relative w-full md:h-[300px] h-[95px] bg-cover bg-center md:rounded-3xl rounded-xl flex items-center justify-center"
                    style={{ backgroundImage: `url(${background})` }}
                  >
                    <div className="absolute md:-top-20 -top-8 z-20 flex items-center justify-center">
                      <img
                        className="md:w-[330px] w-[100px] h-auto md:rounded-[100px] rounded-3xl aspect-square"
                        src={foto}
                        alt="Foto"
                      />
                    </div>
                    <div className="absolute md:top-[14.5rem] top-16 z-10 flex items-center justify-center">
                      <div className="md:p-8 p-3 bg-white flex flex-col items-center justify-center md:rounded-3xl rounded-2xl shadow-lg">
                        <p className="md:text-3xl text-xs font-medium">
                          KH. Muhammad Abrori Sholih
                        </p>
                        <p className="md:text-base text-2xs">
                          Khadimul Ma’had maQis Al-Hamidy 4
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Program />
              <Fasilitas />
              <div className="flex flex-row gap-7">
              <div className="md:block hidden mt-8">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/St-itlR39hs?si=hAJFflBA0LPHbKNa"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen />
              </div>
              <div className="md:block hidden mt-8">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/Ee8VBtY2Zsk"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen />
              </div>
              </div>

              <div className="md:hidden block mt-14 md:mb-14">
                <iframe
                  width="240"
                  height="140"
                  src="https://www.youtube.com/embed/St-itlR39hs?si=hAJFflBA0LPHbKNa"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen />
              </div>
              <div className="md:hidden block mt-14 md:mb-14">
                <iframe
                  width="240"
                  height="140"
                  src="https://www.youtube.com/embed/Ee8VBtY2Zsk"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen />
              </div>
            </div>
            <Penerimaan />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tentang;

import React from "react";
import Sidebar from "../../components/sidebar";
import TambahBerita from '../../components/Admin/tambahBerita'

const BeritaAdmin = () => {
    return (
        <div className="md:ml-64 bg-[#ECECEC] md:flex">
            <Sidebar />
            <TambahBerita />
        </div>
    );
};

export default BeritaAdmin;

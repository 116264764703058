import React, { useState, useEffect } from "react";
import { BsDot } from "react-icons/bs";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { API_URL } from "../../constant";
import iklan from '../../assets/iklan.png'

const Berita = () => {
  const [swiper, setSwiper] = useState(null);
  const [showPrev, setShowPrev] = useState(false);
  const [showNext, setShowNext] = useState(true);
  const [berita, setBerita] = useState([]);
  const [berita1, setBerita1] = useState([]);
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  useEffect(() => {
    if (swiper) {
      setShowPrev(swiper.activeIndex > 0);
      setShowNext(swiper.isEnd === false);

      swiper.on("slideChange", () => {
        setShowPrev(swiper.activeIndex > 0);
        setShowNext(!swiper.isEnd);
      });
    }
  }, [swiper]);

  const fetchBerita = async () => {
    try {
      const response = await axios.get(`${API_URL}/berita`);
      setBerita(response.data.data);
      setBerita1(response.data.data.reverse()[0]);
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchBerita();
  }, []);

  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const truncateText = (text, maxSentences) => {
    const sentences = text.split(".");
    const truncated =
      sentences.slice(0, maxSentences).join(".") +
      (sentences.length > maxSentences ? "..." : "");
    return truncated;
  };

  const truncateText2 = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.substring(0, maxLength) + "...";
  };

  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("id-ID", options);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;
  const totalPages = Math.ceil(berita.length / itemsPerPage);
  const handleClick = (page) => {
    setCurrentPage(page);
  };
  const currentItems = berita.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div id="berita">
      <div className="bg-[#F8A65C] rounded-t-3xl pt-10 mt-1.5">
        <div className="flex items-center justify-center">
          <div className="center md:-mt-24 -mt-20 md:-mb-4">
            <div className=" text-black font-bold bg-[#F8A65C] px-2 md:text-2xl text-md -rounded-md ">
              Berita
            </div>
          </div>
        </div>
        <div className="bg-white rounded-t-3xl shadow-top flex flex-col ">
          <div className="pt-16 pl-12 pr-12">
            <div>
              <h1 className="font-bold text-lg md:text-2xl">Berita Terbaru</h1>
            </div>
            <div
              className=" sm:flex pt-2 cursor-pointer"
              onClick={() => navigate(`/detail/${berita1.id}`)}
            >
              <div
                className="md:w-[45%] md:h-[300px] pt-2 md:pr-8 "
                data-aos="fade-right"
                data-aos-once="true"
              >
                <img
                  src={berita1.image_link}
                  alt="Berita 1"
                  className="object-cover w-full h-full rounded-xl"
                />
              </div>
              <div
                className="md:w-[55%] "
                data-aos="fade-left"
                data-aos-once="true"
              >
                <h1 className="font-bold md:text-xl lg:text-3xl text-sm md:pt=0 pt-4 mb-2">
                  {berita1.judul}
                </h1>
                <p className="text-md md:block hidden">
                  {truncateText(stripHtmlTags(berita1.konten), 5)}
                </p>
                <p className="text-sm md:hidden block">
                  {truncateText(stripHtmlTags(berita1.konten), 2)}
                </p>
                <div className="md:mt-8 mt-4 flex items-end">
                  <a href="" className="text-xs text-primary">
                    {berita1.kategori}
                  </a>
                  <BsDot />
                  <p className="text-xs">{formatDate(berita1.createdAt)}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="my-4 self-center cursor-pointer" data-aos="zoom-in" 
            data-aos-once="true" onClick={() => navigate(`/belanja`)}>
            <img src={iklan} alt="qismart alhamidy" />
          </div>
          <div className=" pl-12 pr-12">
            <div className="pt-8">
              <div className="flex justify-between">
                <h1 className="font-bold text-xl mb-2">Daftar Berita</h1>
                <div className="flex gap-2">
                  <button
                    onClick={() => swiper?.slidePrev()}
                    className=" md:block hidden bg-white border rounded-full p-1"
                  >
                    <MdOutlineChevronLeft size={20} />
                  </button>
                  <button
                    onClick={() => swiper?.slideNext()}
                    className="md:block hidden bg-white border rounded-full p-1"
                  >
                    <MdOutlineChevronRight size={20} />
                  </button>
                </div>
              </div>
              <div className="md:block hidden pb-10">
                <Swiper
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  spaceBetween={20}
                  slidesPerView={3}
                  onSwiper={setSwiper}
                >
                  {berita.slice(1).map((berita, index) => (
                    <SwiperSlide key={index}>
                      <div
                        className=" items-center justify-center cursor-pointer"
                        onClick={() => {
                          navigate(`/detail/${berita.id}`);
                          scrollToTop();
                      }}
                        data-aos="fade-up"
                        data-aos-once="true"
                      >
                        <div className=" items-center justify-center md:p-2 p-0 md:pl-0">
                          <img
                            src={berita.image_link}
                            alt="Berita 1"
                            className="object-cover md:w-full md:h-[250px] rounded-xl md:block hidden"
                          />
                        </div>
                        <div className="p-2 pl-0">
                          <div className="flex items-end md:hidden ">
                            <a href="" className="text-xxs text-primary">
                              {berita.kategori}
                            </a>
                            <BsDot />
                            <p className="text-xxs">
                              {formatDate(berita.createdAt)}
                            </p>
                          </div>
                          <h1 className="font-bold md:text-md text-sm">
                            {berita.judul}
                          </h1>
                          {truncateText(stripHtmlTags(berita.konten), 2)}
                        </div>
                        <div className="mt-8 md:flex hidden">
                          <a href="" className="text-xs text-primary">
                            {berita.kategori}
                          </a>
                          <BsDot />
                          <p className="text-xs">
                            {formatDate(berita.createdAt)}
                          </p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>

              {/* Mobile */}
              <div className="md:hidden block pb-10">
                <div className="">
                  {currentItems.slice(1).map((berita, index) => (
                    <div
                      key={berita.id}
                      className="border rounded-lg p-1 shadow-md mb-4"
                      data-aos="fade-up"
                      data-aos-once="true"
                      onClick={() => navigate(`/detail/${berita.id}`)}
                    >
                      <div className="flex justify-center items-stretch mb-1">
                        <div className="flex w-[45%] object-cover items-stretch justify-center">
                          <img
                            src={berita.image_link}
                            alt="Berita"
                            className="object-cover w-full h-full rounded"
                          />
                        </div>
                        <div className="w-1/2 justify-center items-center p-2">
                          <h1 className="font-bold text-xs">
                            {truncateText2(berita.judul, 30)}
                          </h1>
                        </div>
                      </div>
                      <div>
                        <p className="text-xxs p-1">
                          {truncateText(stripHtmlTags(berita.konten), 1)}
                        </p>
                      </div>
                      <div className="flex pl-1">
                        <a href="#" className="text-xxs text-primary">
                          {berita.kategori}
                        </a>
                        <BsDot />
                        <p className="text-xxs">{formatDate(berita.createdAt)}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex justify-center mt-4">
                  <button
                    onClick={() => handleClick(currentPage - 1)}
                    disabled={currentPage === 1}
                    className="px-3 py-1 mx-1 bg-gray-200 rounded disabled:opacity-50 text-xxs"
                  >
                    Prev
                  </button>
                  {Array.from({ length: totalPages }, (_, i) => (
                    <button
                      key={i}
                      onClick={() => handleClick(i + 1)}
                      className={`text-xxs px-3 py-1 mx-1 rounded ${currentPage === i + 1
                          ? "bg-primary text-white"
                          : "bg-gray-200"
                        }`}
                    >
                      {i + 1}
                    </button>
                  ))}
                  <button
                    onClick={() => handleClick(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    className="text-xxs px-3 py-1 mx-1 bg-gray-200 rounded disabled:opacity-50"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Berita;

// import "./App.css";
import {Routes} from "react-router-dom";
import routes from "./routes";
import Navbar from "./components/Navbar";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
const ScrollToElement = () => {
  const { hash, pathname } = useLocation();

  useEffect(() => {
    if (hash && pathname === '/') {
      const element = document.getElementById(hash.substring(1));
      if (element) {
        const yOffset = -150; // Atur offset sesuai kebutuhan
        const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }, [hash, pathname]);

  return null;
};


function App() {
  
useEffect(() => {
  AOS.init({
      duration: 1200,
  });
}, []);
  return (
    <div className="w-full">
      <ScrollToElement />
      <Routes>
        {routes.map((route) => route)}
      </Routes>
    </div>
  );
}

export default App;

import React from "react";
import { useState, useCallback, useEffect } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import Logo from "../assets/logo.svg";
import { FaWhatsapp } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { GiQueenCrown } from "react-icons/gi";
import { RiDiscountPercentLine } from "react-icons/ri";
import { RiDiscountPercentFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const navigate = useNavigate();
  const handleNav = () => {
    setNav(!nav);
  };
  const handleResize = useCallback(() => {
    if (window.innerWidth >= 768) {
      setNav(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (

    <div className="sticky top-0 z-50 w-full bg-primary">
      <div className="flex justify-between sticky lg:px-32 px-10 items-center mx-auto bg-primary py-4 text-white">
        <img className="hidden md:block cursor-pointer" src={Logo} alt="Logo" width={80} height={87} onClick={() => navigate(`/`)}></img>
        <img className="block md:hidden" src={Logo} alt="Logo" width={50} height={48} onClick={() => navigate(`/`)}></img>
        <ul className="hidden md:flex items-center">
          <li className="px-4 text-md hover:text-secondary">
            <Link to="/" onClick={
              scrollToTop}>Beranda</Link>
          </li>
          <li className="px-4 text-md hover:text-secondary">
            <Link to="/#tentang">Tentang</Link>
          </li>
          <li className="px-4 text-md hover:text-secondary">
            <a href="/#berita">Berita</a>
          </li>
          <li className="px-4 text-md hover:text-secondary bg-[#126F77] py-1.5 rounded-md flex relative">
            <Link className="text-secondary" to="/belanja" onClick={() => {
              scrollToTop();
            }}>Belanja</Link>
            <div className="absolute -top-3 -right-2 text-secondary "><RiDiscountPercentFill size={25} /></div>
          </li>
        </ul>
        <a
          href="https://wa.me/6287860317393"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-secondary md:flex hidden items-center justify-between gap-1 font-medium px-8 py-4 rounded-full text-black text-md"
        >
          <FaWhatsapp size={30} /> Hubungi Kami
        </a>
        <div onClick={handleNav} className="block md:hidden">
          <AiOutlineMenu size={20} />
        </div>
        <div
          className={
            nav
              ? "fixed z-50 top-0 right-0 w-[60%] h-full border-r-gray-900 bg-primary ease-in-out duration-500"
              : "fixed z-50 top-0 right-[-100%] w-[60%] h-full border-r-gray-900 bg-primary ease-in-out duration-500"
          }
        >

          <div className="flex flex-col ">
            <div onClick={handleNav} className="flex self-end mr-10 mt-8 md:hidden">
              <AiOutlineClose size={20} />
            </div>

            <ul className="uppersize py-4 mt-4">
              <li className="p-4 border-b border-gray-600">
                <Link to="/" onClick={() => {
                  scrollToTop();
                  handleNav();
                }}>Beranda</Link>
              </li>
              <li className="p-4 border-b border-gray-600">
                <a href="/#tentang" onClick={handleNav}>Tentang</a>
              </li>
              <li className="p-4 border-b border-gray-600">
                <a href="/#berita" onClick={handleNav}>Berita</a>
              </li>
              <li className="p-4 border-b border-gray-600">
                <Link to="/belanja" onClick={() => {
                  scrollToTop();
                  handleNav();
                }}>Belanja</Link>
              </li>
              <li className="p-4">
                <a
                  href="https://wa.me/6287860317393"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={handleNav}
                >
                  Hubungi Kami
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;

import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../../constant.js";
import TextEditor from "../../components/textEditor.jsx";

const BeritaDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    gambarBerita: null,
    gambarFile: null,
    judul: "",
    penulis: "",
    konten: "",
    kategori: "Dakwah",
  });

  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchProduk = async () => {
      if (id) {
        try {
          const response = await axios.get(`${API_URL}/berita/${id}`);
          const berita = response.data.data[0];
          setFormData({
            gambarBerita: berita.image_link,
            judul: berita.judul,
            penulis: berita.penulis,
            konten: berita.konten,
            kategori: berita.kategori,
          });
        } catch (error) {
          console.error(error);
        }
      }
    };

    fetchProduk();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleContentChange = (newContent) => {
    setFormData({
      ...formData,
      konten: newContent,
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (event) => {
        const imageDataUrl = event.target.result;
        setFormData({
          ...formData,
          gambarBerita: imageDataUrl,
          gambarFile: file,
        });
      };

      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);


    const productData = new FormData();
    productData.append("judul", formData.judul);
    productData.append("penulis", formData.penulis);
    productData.append("kategori", formData.kategori);
    productData.append("konten", formData.konten);

    if (formData.gambarFile) {
      productData.append("gambarBerita", formData.gambarFile);
    }

    try {
      const response = await axios.patch(
        `${API_URL}/berita/${id}`,
        productData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      navigate(`/AdminMaqis/berita`);
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full h-screen">
      <div className="p-10">
        <h1 className="text-xl font-bold mb-5">Update Berita</h1>
        <form onSubmit={handleSubmit}>
          <label className="block text-black font-heavy">Gambar Berita</label>
          {formData.gambarBerita && (
            <img
              src={formData.gambarBerita}
              alt="Berita"
              className="mt-4 h-32 object-cover"
            />
          )}
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            ref={fileInputRef}
            className="mt-1 p-2 border rounded w-full"
          />
          <div className="mb-4 mt-2">
            <h1>Judul Berita</h1>
            <input
              type="text"
              name="judul"
              value={formData.judul}
              onChange={handleChange}
              className="mt-2 p-2 border rounded w-full"
              required
            />
          </div>
          <div className="mb-4 mt-2">
            <h1>Penulis</h1>
            <input
              type="text"
              name="penulis"
              value={formData.penulis}
              onChange={handleChange}
              className="mt-2 p-2 border rounded w-full"
              required
            />
          </div>
          <div className="mb-4">
            <h1>Kategori</h1>
            <select
              name="kategori"
              value={formData.kategori}
              onChange={handleChange}
              className="mt-2 p-2 border rounded w-full"
              required
            >
              <option value="Dakwah">Dakwah</option>
              <option value="Enterpreneur">Enterpreneur</option>
              <option value="Gadget">Gadget</option>
              <option value="Keagamaan">Keagamaan</option>
              <option value="KKN">KKN</option>
              <option value="Kesehatan">Kesehatan</option>
              <option value="Kesenian">Kesenian</option>
              <option value="Kuliner">Kuliner</option>
              <option value="Kebersihan">Kebersihan</option>
              <option value="Otomotif">Otomotif</option>
              <option value="Olahraga">Olahraga</option>
              <option value="Pendidikan">Pendidikan</option>
              <option value="Sosial">Sosial</option>
            </select>
          </div>
          <div className="rounded-lg mb-4">
            <TextEditor
              initialContent={formData.konten}
              onContentChange={handleContentChange}
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white p-2 rounded"
            disabled={loading}
          >
            {loading ? "Submitting..." : "Submit"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default BeritaDetail;

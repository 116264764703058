import React from "react";
import Sidebar from "../../components/sidebar";
import Home from '../../components/Admin/home'

const BeritaAdmin = () => {
  return (
    <div className="md:ml-64 bg-[#ECECEC] md:flex">
      <Sidebar />
      <Home />
    </div>
  );
};

export default BeritaAdmin;

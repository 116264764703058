import React from 'react'
import putra from '../../assets/santri_baru.jpg'
import putri from '../../assets/santri_baru2.jpg'
import { FaWhatsapp } from "react-icons/fa";

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const Fasilitas = () => {

    return (
        <div className="w-full" >
            <div className=" rounded-3xl">
                <div className="rounded-b-3xl">
                    <div className="flex items-center justify-center mt-16 mb-8">
                        <span className="flex-shrink mx-4 bg-secondary px-8 py-1 rounded-full text-lg">
                            Penerimaan Santri Baru
                        </span>
                    </div>
                    <div className="flex justify-center mb-4" data-aos="zoom-in"
                        data-aos-once="true">
                        <div className="container ml-8 mr-8">
                            <div className="p-4 py-1">
                                <div className="grid grid-cols-1 md:grid-cols-[1fr_auto_1fr] md:gap-8 px-4">
                                    <div className="mb-2 rounded-md">
                                        <img className='rounded-lg aspect-[16/9]  object-cover' src={putra} alt="" />
                                    </div>
                                    <div className="hidden md:block h-auto"></div>
                                    <div className="mb-2 rounded-xl">
                                        <img className='rounded-lg aspect-[16/9]  object-cover' src={putri} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="flex justify-center " data-aos="zoom-in"
                        data-aos-once="true">
                        <div className="container ml-8 mr-8">
                            <div className="flex justify-center p-4 py-1">
                                <div className=" grid grid-cols-1 md:grid-cols-[1fr_auto_1fr] md:gap-8 px-2">
                                    <div className="mb-2">
                                        <div className='w-[200px] flex justify-center p-2 bg-primary mx-auto rounded-xl mb-4'>
                                            <p className="text-white text-md">
                                                Syarat Pendaftaran
                                            </p>
                                        </div>
                                        <ol className="list-decimal space-y-2 md:text-md text-sm">
                                            <li>Sowan kepada pengasuh dengan diantar wali atau wakil wali.</li>
                                            <li>Mengisi dan menyerahkan formulir pendaftaran.</li>
                                            <li>Mengisi surat pernyataan siap mentaati peraturan dan kebijakan pesantren yang ditanda tangani santri dan walinya.</li>
                                            <li>Menyerahkan fotokopi kartu keluarga (KK), fotokopi ijazah (jika ada) sebanyak satu lembar.</li>
                                            <li>Menyerahkan foto 3x4 dua lembar.</li>
                                            <li>
                                                Membayar uang pendaftaran dan dana i'anah tahunan dengan ketentuan:
                                                <ol className="list-disc ml-5 space-y-1">
                                                    <li>jika mendaftar sebelum liburan robiul awal maka membayar i'anah pesantren 100%.</li>
                                                    <li>jika mendaftar setelah liburan robiul awal maka membayar i'anah pesantren 50%.</li>
                                                </ol>
                                            </li>
                                            <li>Mentaati tata tertib ma'hadu Al Quran Wa Al Ilmi Al Syarie (maQis) Al Hamidy.</li>
                                        </ol>

                                    </div>
                                    <div className="hidden md:block border-l-2 border-primary h-auto"></div>
                                    <div className="md:mt-0 mt-8 mb-2 md:pl-8">
                                        <div className="mb-2 items-center justify-center">
                                            <div className='md:w-[400px] flex justify-center p-2 bg-primary mx-auto rounded-xl mb-4'>
                                                <p className="text-white text-sm md:text-md text-center">
                                                    Materi Tes Penentuan Kelas Majlis Dirosah
                                                </p>
                                            </div>
                                            <ol className="list-disc md:text-md text-sm">
                                                <li>
                                                    Membaca Al Quran
                                                </li>
                                                <li>
                                                    Menulis arab dan imla’
                                                </li>
                                                <li>
                                                    Membaca kitab
                                                </li>
                                                <li>
                                                    Pengetahuan Agama
                                                </li>
                                            </ol>

                                            <div className="border-4 border-primary rounded-xl p-2 flex flex-col items-center justify-center mt-8">
                                                <h1 className="md:text-3xl font-bold text-primary mb-1">Beasiswa</h1>
                                                <p className="md:text-lg text-sm text-center text-primary">bagi anak yatim, dhuafa, dan santri berprestasi</p>
                                            </div>
                                            <div className='flex justify-center mt-4 p-2 mx-auto rounded-xl'>
                                                <p className="">
                                                    Untuk info lebih lanjut:
                                                </p>
                                            </div>
                                            <a
                                                className='md:w-[200px] items-center flex justify-center p-2 bg-secondary mx-auto rounded-xl'
                                                href="https://wa.me/6287860317393"
                                            >
                                                <FaWhatsapp className='mr-2 md:text-3xl' />
                                                <p className="md:text-md text-xs">
                                                    Hubungi Kami
                                                </p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="p-2 bg-red mt-10"></div>
                </div >
            </div>
        </div>
    )
}

export default Fasilitas